import { render, staticRenderFns } from "./ModalTrade.vue?vue&type=template&id=b776ce1a&scoped=true&"
import script from "./ModalTrade.vue?vue&type=script&lang=js&"
export * from "./ModalTrade.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b776ce1a",
  null
  
)

export default component.exports