<template>
	<div class="modal modal--trade modal-trade">
		<form-trade @close="$emit('close')" :trade="trade" />
	</div>
</template>

<script>
import FormTrade from '@/components/FormTrade'

export default {
	name: 'modalTrade',
	components: {
		FormTrade,
	},
	computed: {
		trade() {
			let trade = {}
			const { counterparty } = this.$route.params
			if (counterparty) {
				trade = this.$store.getters['tools/trade'](counterparty)
			}

			return trade
		},
	},
}
</script>

<style scoped lang="scss">

</style>
