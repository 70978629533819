<template>
	<form class="form form--trade form-trade" @submit.prevent.stop="submitForm(fields)" :novalidate="true">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-1">
				<!-- <text-field class="form__field" v-model="fields.borrower" label="Custom ID" :readonly="true" :disabled="true" /> -->
				<text-field class="form__field" v-model="fields.swCounterParty" label="CounterParty" :readonly="true" :disabled="true" />
			</div>
			<div class="form-row form-row--col-2">
				<text-field class="form__field" v-model="fields.bdTradeId" label="BD Trade ID" :novalidate="true" />
				<text-field class="form__field" v-model="fields.marketWireId" label="Market Wire ID" :novalidate="true" />
				<text-field class="form__field" v-model="fields.clearingHouseId" label="Clearing House ID" :novalidate="true" />
				<text-field class="form__field" v-model="fields.counterPartyId" label="Counterparty ID" :novalidate="true" />
				<text-field class="form__field" v-model="fields.borrower" label="Custom ID" :novalidate="true" />
				<text-field class="form__field" v-model="fields.customer" label="Bank" :novalidate="true" />
			</div>
			<div class="form-row form-row--col-1">
				<select-field class="form__field" v-model="fields.legacyType" label="Legacy" :options="legacyOptions" />
			</div>
		</div>

		<div class="form__actions form__actions--vertical">
			<button type="submit" class="form__action button button--unelevated button--secondary" :disabled="isLoading">
				Save
			</button>
			<a class="button" @click="$emit('close')">
				cancel
			</a>
		</div>
	</form>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import { LegacyType } from '@/lib/enums'

export default {
	name: 'FormTrade',
	components: {
		LoadingSpinner,
		SelectField,
		TextField,
	},
	data: () => ({
		isLoading: false,
		// fields: {},
		// legacyOptions: [
		// 	{
		// 		label: `SouthState`,
		// 		value: 'southstate',
		// 	},
		// 	{
		// 		label: `FMV`,
		// 		value: 'fmv',
		// 	},
		// 	{
		// 		label: `Atlantic`,
		// 		value: 'atlantic',
		// 	},
		// ]
	}),
	props: {
		trade: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		fields() {
			let fields = this.trade || {}
			return fields
		},
		legacyOptions() {
			let options = [{
				value: null,
				label: 'None'
			}]
			for (let key in LegacyType) {
				options.push({
					value: key,
					label: LegacyType[key],
				})
			}

			return options
		},
	},
	methods: {
		async submitForm(fields) {
			this.isLoading = true
			const { bdTradeId, marketWireId, clearingHouseId, legacyType, counterPartyId, customer, borrower, } = fields
			const { counterparty, tradeId, } = this.$route.params
			try {
				await this.$store.dispatch(`tools/updateBvalTrade`, { counterparty, tradeId, bdTradeId, marketWireId, clearingHouseId, legacyType, counterPartyId, customer, borrower, })
				this.$notice(`Trade Updated!`)
			} catch (error) {
				this.$notice(`ERROR: Can not update trade`)
			} finally {
				this.isLoading = false
			}
		},
	},
}
</script>

<style scoped lang="scss">

</style>
